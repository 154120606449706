<template>
  <main class="page">
    <section class="section section--auth c-reset c-auth">
      <div class="auth-container">
        <form @submit="executeRecaptcha" novalidate>
          <div class="form__header mb-4">
            <div class="form__title">
              <h1 class="title title-lg">
                {{ $t('web.reset-password') }}
              </h1>
            </div>
          </div>
          <div class="form__body">
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.PASSWORD"
                  :label="$t('web.pass')"
                  v-model="inputPassword"
                  :type="CONSTANTS.INPUT.META.TYPE.PASSWORD"
                  :validateMethods="
                    validateMethods[CONSTANTS.LOCAL.REFS.PASSWORD]
                  "
                ></c-input>
              </div>
            </div>
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.CONFIRM_PASSWORD"
                  :label="$t('web.confirm-password')"
                  v-model="inputPasswordConfirm"
                  :type="CONSTANTS.INPUT.META.TYPE.PASSWORD"
                  :validateMethods="
                    validateMethods[CONSTANTS.LOCAL.REFS.CONFIRM_PASSWORD]
                  "
                ></c-input>
              </div>
            </div>
            <div class="form__group mt-3">
              <div class="form__item">
                <c-button
                  :value="$t('web.reset')"
                  :loading="cButtonDisabled"
                ></c-button>
                <recaptcha ref="recaptcha" @verify="submitReset"></recaptcha>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import METHODS from '@/utils/Validator/METHODS'
import Validator from '@/utils/Validator'
import * as CONSTANTS from '@/CONSTANTS'
import CInput from '@/components/Input'
import CButton from '@/components/Button'
import Recaptcha from '@/components/Recaptcha'
import { normalizeText } from '@/utils/text'

const _CONSTANTS = {
  REFS: {
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirm_password',
  },

  VALIDATOR_METHODS: {
    COMPARE_PASSWORDS: 'comparePasswords',
  },
}

export default {
  name: 'ResetPassword',

  components: {
    CInput,
    CButton,
    Recaptcha,
  },

  data: function () {
    return {
      cButtonDisabled: false,
      inputPassword: '',
      inputPasswordConfirm: '',
      email: null,
      token: null,
    }
  },

  computed: {
    CONSTANTS: {
      get() {
        return Object.assign({}, CONSTANTS, { LOCAL: _CONSTANTS })
      },
    },
    validateMethods: {
      get() {
        return {
          [_CONSTANTS.REFS.PASSWORD]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.Password')],
            METHODS.IS_PASSWORD,
          ],
          [_CONSTANTS.REFS.CONFIRM_PASSWORD]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.Password')],
            METHODS.IS_PASSWORD,
            _CONSTANTS.VALIDATOR_METHODS.COMPARE_PASSWORDS,
          ],
        }
      },
    },
  },

  beforeMount() {
    this.email = this.$route.query.email
    this.token = this.$route.params.token
    Validator.addNewMethod(
      _CONSTANTS.VALIDATOR_METHODS.COMPARE_PASSWORDS,
      () => {
        const password = this.$refs[_CONSTANTS.REFS.PASSWORD].getValue()
        const confirm_password =
          this.$refs[_CONSTANTS.REFS.CONFIRM_PASSWORD].getValue()
        return {
          success: password === confirm_password,
          error: {
            message: this.$t('passwords-not-equal'),
          },
        }
      }
    )
  },

  methods: {
    executeRecaptcha(event) {
      event?.preventDefault()
      this.$refs.recaptcha.execute()
    },
    submitReset() {
      this.reset()
    },
    reset() {
      const inputs = Object.keys(this.validateMethods).reduce((acc, type) => {
        if (this.$refs[type]) {
          acc.push(this.$refs[type])
        }
        return acc
      }, [])
      const promises = inputs.map((input) => input.validate())
      Promise.all(promises)
        .then(() => {
          this.cButtonDisabled = true

          this.$api.auth
            .reset(
              this.$refs[_CONSTANTS.REFS.PASSWORD].getValue(),
              this.$refs[_CONSTANTS.REFS.CONFIRM_PASSWORD].getValue(),
              this.token
            )
            .then(() => {
              this.$notify({
                group: 'message',
                type: 'success',
                duration: 4500,
                speed: 500,
                title: this.$t('web.notify_password-changed'),
              })
              this.$store.dispatch('user/fetchUser').then(() => {
                this.$router.push({ name: this.$ROUTER.NAME.ACCOUNT.PANEL })
              })
            })
            .catch((error) => {
              // Ужас. Нужно чтобы таких ифов не было
              if (error.errors.data) {
                this.$store.dispatch('app/showDialog', {
                  title: error.title,
                  type: 'error',
                  description: error.errors.data[0],
                })
              } else {
                const componentsMap = {
                  password: this.$refs[_CONSTANTS.REFS.PASSWORD],
                  password_confirmation:
                    this.$refs[_CONSTANTS.REFS.CONFIRM_PASSWORD],
                }
                Object.entries(error.errors).forEach((err) => {
                  componentsMap[err[0]].setError(normalizeText(err[1][0])) // Поработать с ошибками. Добавить валидатор
                })
              }
            })
            .finally(() => {
              this.cButtonDisabled = false
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
@import '../index';
</style>
